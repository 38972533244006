<template>
  <div class="userInfo">
    <div class="userAvatar">
        <span>U</span>
    </div>
    <div @click="open =!open" class="dropdown">
        <p class="userName">UserName</p>
        <svg class="dropdownIcon" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
          <path data-v-2713053b="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
        <div v-if="open" class="dropdown-content" v-on-clickaway="away">
          <button class="logout" @click="logout">Logout</button>
        </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'UserInfo',
  mixins: [clickaway],
  data() {
    return {
      open: false,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login")
      }).catch((e) => {
        console.log(e);
      })
    },
    away() {
      this.open = false;
    },
  }
}
</script>

<style lang="scss" scoped>
 .userInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .userAvatar {
      display: flex; 
      color: #ffffff; 
      font-weight: 500; 
      text-transform: uppercase; 
      justify-content: center; 
      align-items: center; 
      background: #64748b;
      width: 2.5rem; 
      height: 2.5rem; 
      border-radius: 9999px; 
    }
    .userName {
      font-size: 14px;
      color: #334155;
      padding: 0px 4px;
      float: left;
    }
    .dropdownIcon {
      height: 1rem;
      width: 1rem;
      padding-top:15px;
    }
    .dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}
.logout {
  border: none;
  cursor: pointer;
  background: none;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
.dropdown-content:hover  {
   background: #51BBCB;
   .logout {
      color: white;
   }
}
</style>
