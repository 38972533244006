<template>
    <div class="wrap">
        <button v-on="navItem.items.length !== 0 ? { click: clickHandler } : {}" class="button">
            <div class="nav-item">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" width="24px" height="24px" class="icon service-icon" v-html="navItem.iconPath"></svg>
                <p v-show="navItem.items.length !== 0" class="title" :class="{ active : navItem.isActive }">  {{ navItem.title }} </p>
                <p><a v-show="navItem.items.length === 0"  class="title" :href="navItem.url">{{ navItem.title }}</a></p>
                <svg :class="{ rotate : open }" v-if="navItem.items.length !== 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24px" height="24px" class="icon"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
            </div>
        </button>
            <div class="dropdownMenu" v-if="open" v-on-clickaway="away">
                 <component :is="dropdownItem.url.startsWith('/') ? 'router-link' : ''" class="dropdownMenu-text" v-for="dropdownItem in navItem.items" :key="dropdownItem.id" :to="dropdownItem.url" v-text="dropdownItem.title" ></component>
                 <component :is="dropdownItem.url.startsWith('http') ? 'a' : ''" class="dropdownMenu-text" v-for="dropdownItem in navItem.items" :key="'a' + dropdownItem.id" :href="dropdownItem.url" v-text="dropdownItem.title"></component>
            </div>
   </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'NavItem',
  props: {
      'navItem' : Object,
  },
  mixins: [clickaway],
  data() {
      return {
          open: false
      }
  },
  methods: {
    away() {
      this.open = false;
    },
    clickHandler() {
        this.open = !this.open
    }
  }, 
}
</script>

<style lang="scss" scoped>
.wrap {
    position:relative;
}
.button {
    border:none;
    background: none;
    :hover .title {
        color:white;
        transition: 0.5s;
        transition-delay: 0.1s;
    }
    :hover .service-icon {
      stroke:white;
    }
    :hover {
         background: #51BBCB;
         transition: 0.5s;
         transition-timing-function: ease;
         border-radius: 0.375rem; 
    }
    cursor: pointer;
}
.nav-item {
    display: flex;
    align-items: center;
    padding: 0px 14px;
    max-height: 40px;

    a, p {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color:#334155;
        padding: 0px 2px;
        text-decoration: none;
        display: block;
    }
    .icon {
        width: 1.25rem; 
        height: 1.25rem; 
        color:#94a3b8;    
        transition: transform 0.3s ease-in;
    }
    
}
  .dropdownMenu {
    z-index: 999;
    width: 240px;
    margin: 0.125rem0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
    transform-origin: top right;
    position: absolute;
    right: -35px;

    .dropdownMenu-text {
        margin:0;
        padding: 20px 20px;
        cursor: pointer;
        transition: 1s;
        transition-timing-function: ease;
        display: block;
        text-decoration: none;
        color: black;
        
    }
    .dropdownMenu-text:hover {
         background: #51BBCB;
         color: white;
    }
  }
  .rotate {
      transform: rotate(180deg);
      stroke: white;
      background: #51BBCB;
      border-radius: 50px;
     
  }
  .active {
      font-weight: bold;
  }
  .router-link-active {
      font-weight:500;
  }
  .router-link-active::after {
    display:block;
    clear:both;
    content : "";
    position: relative;
    left    : 0;
    bottom  : 0;
    max-width:250px;
    height  : 1px;
    width   : 100%;  /* or 100px */
    border-bottom:1px solid #51BBCB;
    margin:0 auto;
    padding:4px 0px;
  }
 
</style>