<template>
      <nav>
        <nav-item-component v-for="(navItem, index) in navItems" :key="index" :navItem="navItem" />
      </nav>
</template>

<script>
import NavItem from '../components/NavItem.vue'

export default {
  name: 'MainNav',
  components: {
   'nav-item-component' : NavItem
  },
  data() {
    return {
      navItems: [
        {
          title: 'Data Import',
          items: [
            {
              id: 0,
              title : 'Dataset from API',
              url : '/import-data'
            },
            {
              id: 1,
              title : 'Dataset from File',
              url : '/file-browser'
            },
            {
              id: 3,
              title : 'Offline Anonymisation',
              url : '/Experimentation'
            },
          ],
          iconPath: '<path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path><path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>',
          isActive: true,
        },
        {
          title: 'Experimentation',
          url: 'http://62.217.122.163:3007/',
          items: [],
          external: true,
          iconPath: '<path fill-rule="evenodd" d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z" clip-rule="evenodd"></path>'
        },
        {
          title: 'Pipelines',
          items: [
              {
              id: 0,
              title : 'Model Configuration',
              url : 'http://62.217.122.163:3007/'
            },
          ],
          iconPath: '<path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path>'
        },
        {
          title: 'Asset Management',
          items: [
            {
              id: 0,
              title : 'My Datasets',
              url : '/MyDatasets'
            },
            {
              id: 1,
              title : 'My Models',
              url : '/MyModels'
            },
            {
              id: 2,
              title : 'My Result',
              url : '/MyResult'
            },
            {
              id: 3,
              title : 'My File',
              url : 'http://62.217.122.163:3006/'
            },
            {
              id: 4,
              title : 'XMANAI Data Model',
              url : 'http://xmanaikbz.ddns.net/'
            },
          ],
          iconPath: '<path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>'
        },
        {
          title: 'Catalogue',
          items: [
            {
              id: 0,
              title : 'Search',
              url : '/Search'
            },
            {
              id: 1,
              title : 'Sharing Agreements',
              url : '/SharingAgreements'
            },
          ],
          iconPath: '<path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>'
        },
        {
          title: 'Admin',
          items: [
            {
              id: 0,
              title : 'Organization',
              url : '/Organization'
            },
            {
              id: 1,
              title : 'Data Model Management',
              url : 'http://xmanaikbz.ddns.net/'
            },
          ],
          iconPath: '<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  display: flex;
}

</style>