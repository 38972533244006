<template>
    <header>
      <div class="header">
        <router-link to="/data-import">
          <img class="logo" src="../assets/images/Logo_Complete_Color.svg" alt="Logo">
        </router-link>
        <navigation-component />
        <user-info />
      </div>
    </header>
</template>
<script>
import UserInfo from './UserInfo.vue'
import Nav from '../components/Nav.vue'


export default {
  name: 'HeaderComponent',
  components: {
    'user-info' : UserInfo,
    'navigation-component' : Nav
  }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    max-width: 1480px;
    margin: 0 auto;
}
  header {
    max-width: 100%;
    border-bottom: 1px solid #e5e5e5;
    img.logo {
      width: 140px;
      height: 40px;
    }
  }
</style>