<template>
  <div id="app">
     <main class="content">
        <DataImport />
      </main>
      <Footer />
  </div>
</template>

<script>
import DataImport from './components/DataImport.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    DataImport,
    Footer
  }
}
</script>

<style scoped>
* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content {
  position: relative;
}
</style>
