<template>
    <footer class="footer">
        <div class="flex">
            <div>
                <img src="../assets/images/footer_logo.png" alt="EU Logo" class="footer-logo">
                 <p class="logo-text">This project has received funding from the European Unio`s Horizon 2020 research and innovation programme under Grant agreement No 957362</p>
            </div>
        <span class="links">
            <a class="link" href="https://xmanai-compliance-xmanai.apps.osc.fokus.fraunhofer.de/imprint" target="_blank" >imprint</a>
            <a class="link" href="https://xmanai-compliance-xmanai.apps.osc.fokus.fraunhofer.de/privacy" target="_blank" >privacy policy</a>
        </span>
    </div>
    </footer>
</template>
<script>
export default {
    name: 'Footer-main'
}
</script>
<style>
.flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer {
    position: fixed;
    background: rgba(242, 242, 242, 0.8);
    bottom:0;
    left: 0;
    width:100%;
    padding: 20px;
}
.footer-logo {
    max-height: 60px;
    clear: right;
    float: left;
    padding-right: 15px;
}
.logo-text {
    max-width: 400px;
    text-align: left;
    font-size: 12px;
    margin: 0;
    color: #334155;
}
.links {
    display: flex;
    flex-direction: row;
    align-self: center;
    padding-right: 40px;
}
.link {
    text-decoration: none;
    color: black;
    margin: 0px 10px;
}
.link:hover {
    color:#009CB4;
    font-weight: bold;
}
</style>