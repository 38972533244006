<template>
    <div>
        <Header />
       <h1>Data Import</h1>
       <div class="flex">
           <div class="item" style="cursor:not-allowed;">
               <span class="icon-bg">
                   <i class="file-icon api"></i>
               </span>
               <h2>Import from API</h2>
               <p>Create, automate and manage pipelines that import data from external APIs.</p>
           </div>
           <button @click="goTofileBrowser">
                <div class="item">
                    <span class="icon-bg">
                        <i class="file-icon file"></i>
                        </span>
                    <h2>Import from File</h2>
                    <p>Import and describe data from pre existing binary files.</p>
                </div>
           </button>
       </div>
    </div>
</template>
<script>
import router from '../router'
import Header from './Header.vue'

export default {
  name: 'DataImport',
  methods: {
      goTofileBrowser() {
          router.push('/file-browser')
      }
  },
  components: {
    Header,
  },
}
</script>
<style scoped>
h1 {
    color: #606060;
    font-size: 32px;
    margin: 0 auto;
    max-width: 300px;
    padding: 20px 0px;
    text-align: center;
}
h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    margin-bottom: 0;
}
.flex {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 1200px;
    justify-content: center;
}
.item {
    max-width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background: #F8F8F8;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 10px;
    text-align: center;
}
p {
    padding: 0px 30px;
    max-width: 300px;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #94a3b8;
    text-align: left;
}
.icon-bg {
    background: #009CB4;
    margin-top:-30px;
    padding: 5px;
    border-radius: 5px;
}
.file-icon {
	display: block;
	width: 60px;
	height: 30px;
	padding: 10px 10px;
    border-radius: 5px;
    margin: 0 auto;
}
.file {
    background: url('../assets/images/file-svg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

}
.api {
    background: url('../assets/images/api.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
button {
    all: unset;
}

</style>